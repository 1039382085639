import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Image from 'gatsby-image';
import ReactGA from "react-ga4";
import Button from '../buttons/roundedButton';
import { DARK_BLUE, TURQUOISE, WHITE } from '../../constants/colors';
import {
  CONTAINER_LAPTOP_WIDTH,
  CONTAINER_WIDTH, MOBILE, TABLET, media, LAPTOP,
} from '../../constants/screens';

const Subscribe = ({ image }) => (
  <SubscribeContainer>
    <MainContent>
      <SubscribeImageWrapper>
        <Image sizes={image} />
      </SubscribeImageWrapper>
      <SubscribeContent>
        <SubscribeTextContainer>
          <SubscribeText
            color={TURQUOISE}
          >
            /Keep the pulse on
            the latest developer
            trends & data with
          </SubscribeText>
          <SubscribeText
            color={WHITE}
          >
            our newsletter
          </SubscribeText>
        </SubscribeTextContainer>
        <Button
          text="subscribe"
          color={TURQUOISE}
          hoverTextColor={WHITE}
          tag="a"
          href="https://zc.vg/5MXSf"
          target="_blank"
          onClick={() => ReactGA.event({
            category: 'Newsletter subscription',
            action: 'Newsletter subscribe [btn]',
            label: 'SlashData newsletter opt-in',
          })}
        />
      </SubscribeContent>
    </MainContent>
  </SubscribeContainer>
);

const SubscribeContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${DARK_BLUE};
  padding: 90px 5% 75px 5%;
  
  @media screen and (max-width: ${LAPTOP}) {
    padding: 75px 5% 75px;
  }
`;

const SubscribeImageWrapper = styled.div`
  max-width: 700px;
  width: 100%;
  max-height: 510px;
  height: 100%;
  flex: 0.6;
  
  @media screen and (max-width: ${LAPTOP}) {
    max-width: 500px;
    max-height: 400px;
  }
  
  @media screen and (min-width: ${MOBILE}) and (max-width: ${TABLET}) {
    max-width: 600px;
    margin-bottom: 100px;
  }
  
  @media screen and (max-width: ${MOBILE}) {
    max-width: 600px;
    margin-bottom: 60px;
  }
`;

const SubscribeContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0.4;
  align-items: center;
  justify-content: center;
`;

const SubscribeText = styled.h4`
  margin: 0;
  font-size: 40px;
  text-align: center;
  text-transform: uppercase;
  line-height: normal;
  color: ${props => props.color};
  
  @media screen and (max-width: ${LAPTOP}) {
    font-size: 30px;
  }
  
  @media screen and (max-width: ${MOBILE}) {
    font-size: 24px;
  }
`;

const SubscribeTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 90px;
  
  @media screen and (max-width: ${LAPTOP}) {
    margin-bottom: 60px;
  }
`;

export const MainContent = styled.div`
  display: flex;
  flex-direction: row;
  max-width:  ${CONTAINER_WIDTH};
  width: 100%;
  justify-content: space-between;
  align-items: center;
  
  ${media.laptop`
    max-width: ${CONTAINER_LAPTOP_WIDTH};
  `};
  
  @media screen and (max-width: ${TABLET}) {
    flex-direction: column;
    align-items: center;
  }
`;

Subscribe.propTypes = {
  image: PropTypes.shape().isRequired,
};

export default Subscribe;
